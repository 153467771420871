* {
  box-sizing: border-box;
}

*:focus {
  outline: 0;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #000;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #000
}

*::-webkit-scrollbar-thumb {
  background-color: #1a76ca;
  border-radius: 5px;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    background-color: #000;
    color: #1a76ca;
    padding: 2px;
    border-radius: 5px;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.global-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.global-modal .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.global-modal .modal-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 500px;
  background-color: #18242f;
  border-radius: 10px;
  padding: 15px 20px;
  z-index: 2;
}

.global-modal .modal-box i {
  font-size: 55px;
  color: #fff;
  margin: 20px 0 25px;
}

.global-modal .modal-box i.fa-exclamation-triangle.warn {
  color: #ffb636;
}

.global-modal .modal-box i.fa-exclamation-triangle.danger {
  color: #ff3600;
}

.global-modal .modal-box .text p {
  text-align: center;
  color: white;
}

.global-modal .modal-box .action-btns {
  display: flex;
  justify-content: center;
  margin: 30px 0 15px;
}

.global-modal .modal-box .action-btns .btn {
  padding: 10px 30px;
  border-radius: 5px;
  color: white;
  background-color: #1f2f3e;
  margin: 0 10px;
  cursor: pointer;
}

.global-modal .modal-box .action-btns .btn:first-of-type {
  background-color: #ff3600;
}

.global-modal .modal-box .action-btns .btn:last-of-type {
  background-color: #0dac50;
}

.view-holder {
  display: flex;
  position: relative;
  min-height: 100vh;
  background-color: #131c25;
}

.view-holder.logged-in {
  padding-left: 225px;
}

.view-holder .login-overlay {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #131c25;
  z-index: 10;
}

.view-holder .login-overlay.active {
  display: flex;
}

.view-holder .login-overlay img {
  height: 25px;
  margin-bottom: 20px;
}

.view-holder .login-overlay .login-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  background-color: #1f2f3e;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding-bottom: 40px;
  margin-bottom: 60px;
}

.view-holder .login-overlay .login-widget .head {
  width: 100%;
  color: white;
  background-color: #18242f;
  text-align: center;
  padding: 10px 0;
  font-size: 18px;
}

.view-holder .login-overlay .login-widget .error-area {
  width: 100%;
  color: white;
  background-color: #ff3600;
  text-align: center;
  padding: 7px 0;
  font-size: 12px;
}

.view-holder .login-overlay .login-widget .input-group {
  width: 100%;
  padding: 0 25px;
  margin-bottom: 20px;
}

.view-holder .login-overlay .login-widget .input-group.username-inp {
  margin-top: 40px;
}

.view-holder .login-overlay .login-widget .input-group .label {
  color: #1a76ca;
  font-size: 16px;
  margin-bottom: 5px;
}

.view-holder .login-overlay .login-widget .input-group input {
  width: 100%;
  height: 40px;
  background-color: #131c25;
  border: 1px solid #1a76ca;
  color: white;
  padding-left: 15px;
  margin-bottom: 10px;
}

.view-holder .login-overlay .login-widget .login-btn {
  width: calc(100% - 50px);
  padding: 10px;
  text-align: center;
  color: white;
  background-color: #1a76ca;
  margin: 0 25px 10px;
  cursor: pointer;
}

.view-holder .login-overlay .login-widget .forgot-pass {
  color: #1a76ca;
  text-align: center;
}

.topnav {
  display: none;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  background-color: #18242f;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.topnav .logo-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #18242f;
  padding: 15px;
}

.topnav .logo-holder img {
  height: 30px;
}

.topnav .links-holder {
  display: flex;
}

.topnav .links-holder a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #18242f;
  color: white;
  transition: all 0.25s ease;
}

.topnav .links-holder a:hover, .topnav .links-holder a.active {
  background-color: #1a76ca;
}

.sidenav {
  position: fixed;
  top: 0;
  left: 0;
  width: 225px;
  height: 100vh;
  background-color: #1f2f3e;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.view-holder.logged-out .sidenav {
  display: none;
}

.sidenav .logo-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #18242f;
  padding: 15px 0;
}

.sidenav .logo-holder img {
  height: 20px;
  margin-top: 10px;
}

.sidenav .profile-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.sidenav .profile-area .avatar {
  height: 120px;
  width: 120px;
  border: 2px solid #1a76ca;
  margin: 30px 0 10px;
  object-fit: contain;
  background-color: rgba(0,0,0,0.2);
}

.sidenav .profile-area p {
  color: white;
  font-size: 18px;
  text-align: center;
}

.sidenav .profile-area p span {
  font-size: 14px;
  opacity: 0.7;
}

.sidenav .links-holder {
  display: flex;
  flex-direction: column;
  height: calc(100% - 290px);
  overflow: auto;
}

.sidenav .links-holder a {
  position: relative;
  padding: 15px 10px;
  color: white;
  transition: all 0.25s ease;
}

.sidenav .links-holder a.active,
.sidenav .links-holder a:hover {
  background-color: #1a76ca;
}

.sidenav .links-holder a i {
  width: 20px;
  margin-right: 10px;
}

.main-page {
  padding: 30px;
  padding-bottom: 0;
  width: 100%;
  overflow-x: hidden;
}

.product-panel {
  width: 100%;
}

.product-panel .copy-settings {
  position: fixed;
  bottom: 10px;
  right: -300px;
  background-color: #18242f;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 25px;
  color: white;
  z-index: 100;
  transition: all 0.25s ease;
}

.product-panel .copy-settings.active {
  right: 10px;
}

.product-panel .copy-settings .title {
  margin-bottom: 20px;
}

.product-panel .copy-settings .input-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.product-panel .copy-settings .input-group .label {
  margin-left: 5px;
}

.product-panel .copy-settings .save-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 35px;
  background-color: #1a76ca;
  border-radius: 5px;
  margin: 30px 0 0;
  cursor: pointer;
}

.add-btn-area {
  display: flex;
  justify-content: flex-end;
  padding: 0 30px 0 0;
}

.add-btn-area button {
  min-width: 180px;
  padding: 10px 20px;
  border-radius: 20px;
  border: 0;
  color: white;
  background-color: #1a76ca;
  cursor: pointer;
}

.page-actions {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 20px;
}

.filters-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #1f2f3e;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 15px 20px;
  border-radius: 5px;
}

.filters-bar .search {
  position: relative;
}

.filters-bar .search .suggestions {
  position: absolute;
  width: 300px;
  max-height: 100px;
  background-color: #18242f;
  overflow: auto;
  z-index: 10;
}

.filters-bar .search .suggestions li {
  padding: 5px 10px;
  border-bottom: 1px solid #131c25;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.25s ease;
}

.filters-bar .search .suggestions li:hover {
  background-color: #1a76ca;
}

.filters-bar input {
  width: 300px;
  height: 30px;
  border: 0;
  border-radius: 5px;
  background-color: #18242f;
  border: 1px solid #1a76ca;
  color: #fff;
  padding-left: 15px;
  margin-right: 10px;
}

.filters-bar .dropdown-filters {
  display: flex;
  align-items: center;
}

.filters-bar .dropdown-filters .label {
  margin: 0 10px;
  color: white;
}

.filters-bar select {
  width: 150px;
  height: 30px;
  border: 0;
  border-radius: 5px;
  background-color: #18242f;
  border: 1px solid #1a76ca;
  color: #fff;
  padding-left: 15px;
}

.product-panel .change-dep-prod {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
}

.product-panel .change-dep-prod .mod {
  width: 500px;
  background-color: #1f2f3e;
  border-radius: 10px;
}

.product-panel .change-dep-prod .mod .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #1a76ca;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;
}

.product-panel .change-dep-prod .mod .head i {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  cursor: pointer;
  background-color: #ff3600;
}

.product-panel .change-dep-prod .mod .body {
  padding: 10px 20px;
}

.product-panel .change-dep-prod .mod .body .change-inputs {
  margin: 10px 0;
}

.product-panel .change-dep-prod .mod .body .change-inputs .label {
  color: white;
  margin-bottom: 15px;
  text-align: center;
  font-size: 14px;
}

.product-panel .change-dep-prod .mod .body .change-inputs .input-holder {
  display: flex;
  align-items: center;
  position: relative;
}

.product-panel .change-dep-prod .mod .body .change-inputs .input-holder .prod-suggestions {
  position: absolute;
  top: 32px;
  width: 340px;
  max-height: 200px;
  background-color: #18242f;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow: auto;
  z-index: 2;
}

.product-panel .change-dep-prod .mod .body .change-inputs .input-holder .prod-suggestions li {
  padding: 5px 10px;
  color: white;
  font-size: 14px;
  border-bottom: 1px solid #131c25;
  transition: all .25s ease;
  cursor: pointer;
}

.product-panel .change-dep-prod .mod .body .change-inputs .input-holder .prod-suggestions li:hover {
  background-color: #1a76ca;
}

.product-panel .change-dep-prod .mod .body input {
  height: 30px;
  width: 350px;
  border: 1px solid #1a76ca;
  border-radius: 5px;
  background-color: #131c25;
  color: white;
  padding-left: 10px;
  margin-right: 15px;
}

.product-panel .change-dep-prod .mod .body .create-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 10px;
  padding: 0 20px;
  color: white;
  background-color: #1a76ca;
  cursor: pointer;
}

.product-panel .change-dep-prod .mod .body .create-new {
  height: 0;
  overflow: hidden;
  transition: all .25s ease;
}

.product-panel .change-dep-prod .mod .body .create-new.active {
  height: 450px;
}

.product-panel .change-dep-prod .mod .body .create-new .create-prod {
  text-align: center;
  font-size: 14px;
  color: white;
  margin: 10px 0;
}

.product-panel .change-dep-prod .mod .body .create-new .label {
  margin: 0 0 10px;
  color: white;
}

.product-panel .change-dep-prod .mod .body .create-new input {
  width: 100%;
  margin-bottom: 10px;
}

.product-panel .change-dep-prod .mod .body .create-new .create-btn {
  width: fit-content;
  margin: 10px auto;
}

.product-panel .products-container {
  height: calc(100vh - 130px);
  padding: 10px;
  overflow: auto;
}

.product-panel .products-container .infinite-scroll-container {
  overflow: visible !important;
}

.product-panel .products-container .product {
  position: relative;
  height: 110px;
  min-width: 1600px;
  background-color: #18242f;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: visible !important;
}

.product-panel .products-container .product.active {
  height: auto;
}

.product-panel .products-container .product .top-side {
  display: flex;
  position: relative;
  background-color: #1f2f3e;
  color: white;
  padding: 15px 25px;
  padding-left: 35px;
  border-radius: 10px;
}

.product-panel .products-container .product .top-side .dots-menu {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  position: absolute;
  top: 10px;
  right: 10px;
}

.product-panel .products-container .product .top-side .dots-menu .menu-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  background-color: #18242f;
  cursor: pointer;
  transition: all .25s ease;
}

.product-panel .products-container .product .top-side .dots-menu .menu-btn:hover {
  background-color: #1a76ca;
}

.product-panel .products-container .product .top-side .dots-menu .h-menu {
  position: absolute;
    top: 33px;
    width: 160px;
    background-color: #18242f;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    overflow: auto;
    z-index: 10;
}

.product-panel .products-container .product .top-side .dots-menu .h-menu .menu-item {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  font-size: 12px;
  border: 0;
  border-bottom: 1px solid #131c25;
  color: white;
  padding: 10px 15px;
  background-color: transparent;
  cursor: pointer;
  transition: all .25s ease;
}

.product-panel .products-container .product .top-side .dots-menu .h-menu .menu-item.no-option-btn {
  opacity: 0.5;
  cursor: not-allowed;
}

.product-panel .products-container .product .top-side .dots-menu .h-menu .menu-item:hover {
  background-color: #1a76ca;
}

.product-panel .products-container .product .top-side .dots-menu .h-menu .menu-item.delete-btn:hover {
  background-color: #ff3600;
}

.product-panel .products-container .product .top-side .dots-menu .h-menu .menu-item.no-option-btn:hover {
  background-color: transparent;
}

.product-panel .products-container .product .top-side .expander-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 25px;
  height: 100%;
  top: 0px;
  left: 0px;
  font-size: 25px;
  color: white;
  background-color: #18242f;
  cursor: pointer;
  transition: all 0.25s ease;
}

.product-panel .products-container .product .top-side .expander-icon i {
  transition: all 0.4s ease;
}

.product-panel .products-container .product .top-side .expander-icon.active i {
  transform: rotate(90deg);
}

.product-panel .products-container .product .top-side .expander-icon:hover {
  color: #1a76ca;
}

.product-panel .products-container .product .top-side .prod-img {
  height: 80px;
  width: 80px;
  min-width: 80px;
  margin-right: 20px;
}

.product-panel .products-container .product .top-side .prod-img img {
  height: 100%;
}

.product-panel .products-container .product .top-side .prod-details {
  width: 100%;
  min-width: 1050px;
}

.product-panel
  .products-container
  .product
  .top-side
  .prod-details
  .editor-holder {
  display: flex;
  align-items: center;
}

.product-panel
  .products-container
  .product
  .top-side
  .prod-details
  .editor-holder
  i {
  font-size: 14px;
  margin-left: 10px;
  margin-bottom: 10px;
  opacity: 0.1;
  cursor: pointer;
  transition: all 0.25s ease;
}

.product-panel
  .products-container
  .product
  .top-side
  .prod-details
  .editor-holder
  i.fas.fa-check:hover {
  color: green;
}

.product-panel
  .products-container
  .product
  .top-side
  .prod-details
  .editor-holder
  i.fas.fa-times:hover {
  color: red;
}

.product-panel
  .products-container
  .product
  .top-side
  .prod-details
  .editor-holder
  i:hover {
  color: #1a76ca;
  opacity: 1;
}

.product-panel
  .products-container
  .product
  .top-side
  .prod-details
  .edit-input {
  height: 21px;
  margin-bottom: 10px;
  border: 1px solid #1a76ca;
  background-color: #131c25;
  padding-left: 5px;
  color: #fff;
}

.product-panel .products-container .product .top-side .prod-details .title {
  width: fit-content;
  margin-bottom: 10px;
  font-size: 18px;
  cursor: pointer;
}

.product-panel .products-container .product .top-side .prod-details .title i {
  font-size: 14px;
  margin-left: 5px;
  opacity: 0;
  cursor: pointer;
  transition: all 0.25s ease;
}

.product-panel
  .products-container
  .product
  .top-side
  .prod-details
  .title:hover
  i {
  color: #1a76ca;
  opacity: 1;
}

.product-panel
  .products-container
  .product
  .top-side
  .prod-details
  .title
  i.fas.fa-times:hover {
  color: red;
  opacity: 1;
}

.product-panel .products-container .product .top-side .prod-details .details {
  display: flex;
  justify-content: space-between;
}

.product-panel
  .products-container
  .product
  .top-side
  .prod-details
  .details
  .group {
  width: calc(100% / 7);
}

.product-panel
  .products-container
  .product
  .top-side
  .prod-details
  .details
  .group
  .label {
  font-size: 14px;
  color: #1a76ca;
  margin-bottom: 3px;
}

.product-panel
  .products-container
  .product
  .top-side
  .prod-details
  .details
  .group
  .value
  i {
  font-size: 12px;
  margin-left: 5px;
  opacity: 0;
  cursor: pointer;
  transition: all 0.25s ease;
}

.product-panel
  .products-container
  .product
  .top-side
  .prod-details
  .details
  .group
  .value:hover
  i {
  color: #1a76ca;
  opacity: 1;
}

.product-panel
  .products-container
  .product
  .top-side
  .prod-details
  .details
  .group
  .editfield {
  position: relative;
}

.product-panel
  .products-container
  .product
  .top-side
  .prod-details
  .details
  .group
  .editfield
  .suggestions {
  height: 85px;
  margin-top: 7px;
  overflow: auto;
}

.product-panel
  .products-container
  .product
  .top-side
  .prod-details
  .details
  .group
  .editfield
  .suggestions
  li {
  padding: 8px 10px;
  border-bottom: 1px solid #131c25;
  background-color: #18242f;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.25s ease;
}

.product-panel
  .products-container
  .product
  .top-side
  .prod-details
  .details
  .group
  .editfield
  .suggestions
  li:hover {
  background-color: #1a76ca;
}

.product-panel
  .products-container
  .product
  .top-side
  .prod-details
  .details
  .group
  .editfield
  input {
  width: 100px;
  height: 21px;
  border: 1px solid #1a76ca;
  background-color: #131c25;
  color: white;
  padding-left: 5px;
}

.product-panel
  .products-container
  .product
  .top-side
  .prod-details
  .details
  .group
  .editfield
  select {
  width: 100px;
  height: 21px;
  border: 1px solid #1a76ca;
  background-color: #131c25;
  color: white;
  padding-left: 5px;
}

.product-panel
  .products-container
  .product
  .top-side
  .prod-details
  .details
  .group
  .editfield
  i {
  opacity: 0.5;
  cursor: pointer;
  transition: all 0.25s ease;
}

.product-panel
  .products-container
  .product
  .top-side
  .prod-details
  .details
  .group
  .editfield
  i.fa-check:hover {
  color: green;
  opacity: 1;
}

.product-panel
  .products-container
  .product
  .top-side
  .prod-details
  .details
  .group
  .editfield
  i.fa-times:hover {
  color: red;
  opacity: 1;
}

.product-panel
  .products-container
  .product
  .top-side
  .prod-details
  .details
  .group
  .editfield
  i {
  margin-left: 8px;
}

.product-panel
  .products-container
  .product
  .top-side
  .prod-details
  .details
  .group.two-action-btns
  .act-btn {
  margin: 0 5px;
}

.product-panel .products-container .product .customer-groups {
  padding: 20px;
  color: white;
  opacity: 0;
  transition: all .25s ease;
}

.product-panel .products-container .product.active .customer-groups {
  animation: fadeInCustomers 1s forwards;
}

@keyframes fadeInCustomers {
  0% {
    display: block;
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.product-panel .products-container .product .customer-groups .customer {
  border-bottom: 2px solid #1a76ca;
  margin-bottom: 15px;
}

.product-panel .products-container .product .customer-groups .customer .customer-entry {
  background-color: #233648;
  border-radius: 10px;
}

.product-panel .products-container .product .customer-groups .customer .customer-entry:nth-of-type(odd) {
  background-color: #27415a;
}

.product-panel .products-container .product .customer-groups .head {
  width: 100%;
  color: #1a76ca;
  border-bottom: 2px solid #1a76ca;
  padding: 8px 0;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .head
  .editor-holder {
  display: flex;
  align-items: center;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .head
  .editor-holder
  i {
  font-size: 14px;
  margin-left: 10px;
  margin-bottom: 10px;
  opacity: 0.1;
  cursor: pointer;
  transition: all 0.25s ease;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .head
  .editor-holder
  i.fas.fa-check:hover {
  color: green;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .head
  .editor-holder
  i.fas.fa-times:hover {
  color: red;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .head
  .editor-holder
  i:hover {
  color: #1a76ca;
  opacity: 1;
}

.product-panel .products-container .product .customer-groups .head .edit-input {
  height: 21px;
  margin-bottom: 10px;
  border: 1px solid #1a76ca;
  background-color: #131c25;
  padding-left: 5px;
  color: #fff;
}

.product-panel .products-container .product .customer-groups .head .title {
  margin-bottom: 10px;
  font-size: 18px;
  cursor: pointer;
}

.product-panel .products-container .product .customer-groups .head .title i {
  font-size: 14px;
  margin-left: 5px;
  opacity: 0;
  cursor: pointer;
  transition: all 0.25s ease;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .head
  .title:hover
  i {
  color: #1a76ca;
  opacity: 1;
}

.download-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
  }

  .lds-hourglass {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid #1a76ca;
    border-color: #1a76ca transparent #1a76ca transparent;
    animation: lds-hourglass 1.2s infinite;
  }
  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }

.product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry .copy-notify {
    position: fixed;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px 40px;
    background-color: #1a76ca;
    border-radius: 10px;
    z-index: 10;
    transition: all .25s ease;
  }

  .product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry .copy-notify.active {
    bottom: 10px;
  }

  .product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry
  .entry .group {
    width: calc(100%/7);
  }

.product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry
  .entry
  .name-holder {
  display: flex;
  align-items: center;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry
  .entry
  .name-holder
  i {
  color: #1a76ca;
  font-size: 14px;
  margin-left: 8px;
  opacity: 0;
  cursor: pointer;
  transition: all .25s ease;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry
  .entry
  .name-holder:hover
  i {
    opacity: 1;
  }

.product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry
  .entry
  .editor {
  display: flex;
  align-items: center;
  position: relative;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry
  .entry
  .editor
  input {
  width: 130px;
  height: 25px;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry
  .entry
  .editor
  i {
  margin-left: 7px;
  color: #ff3600;
  cursor: pointer;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry
  .entry
  .editor
  .suggestions {
  position: absolute;
  top: 28px;
  width: 100%;
  max-height: 100px;
  background-color: #1f2f3e;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow: auto;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry
  .entry
  .editor
  .suggestions
  li {
  padding: 7px 10px;
  font-size: 12px;
  border-bottom: 1px solid #131c25;
  cursor: pointer;
  transition: all 0.25s ease;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry
  .entry
  .editor
  .suggestions
  li:hover {
  background-color: #1a76ca;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry
  .entry .action-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 30px;
    width: 120px;
    margin-left: auto;
    padding: 0 20px;
    background-color: #1a76ca;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }

  .product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry
  .entry .action-btn i.fa-caret-down {
    margin-left: 7px;
  }

  .product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry
  .entry .action-btn .actions-list {
    position: absolute;
    top: 33px;
    width: 180px;
    background-color: #1f2f3e;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    overflow: auto;
    z-index: 10;
  }

.product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry
  .entry-viewer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry
  .entry-viewer
  iframe {
  background-image: url("../images/checkered.png");
  background-size: 50%;
  margin: 50px 0;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry
  .entry-viewer
  iframe.small {
  height: 300px;
  width: 100%;
  max-width: 500px;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry
  .entry-viewer
  iframe.medium {
  height: 500px;
  width: 800px;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry
  .entry-viewer
  iframe.large {
  height: 900px;
  width: 1100px;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry
  .entry-viewer
  .viewer-resizer {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry
  .entry-viewer
  .viewer-resizer
  .btn {
  font-size: 10px;
  padding: 5px 15px;
  background-color: #1a76ca;
  color: white;
  margin: 10px;
  cursor: pointer;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry
  .entry-viewer
  .viewer-resizer
  .btn.active {
  background-color: #10416e;
}

.product-panel .products-container .product .customer-groups .entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 1000px;
  padding: 0px 30px;
  margin: 7px;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .customer-entry:last-of-type {
  margin-bottom: 25px;
}

.product-panel .products-container .product .customer-groups .entry .img {
  position: relative;
  height: 90px;
  width: 90px;
  background-color: #fff;
  margin-right: 20px;
  margin-top: 7px;
  cursor: pointer;
  overflow: hidden;
}

.product-panel .products-container .product .customer-groups .entry .img img {
  width: 100%;
}

.product-panel .products-container .product .customer-groups .entry .img .overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  font-size: 25px;
  transition: all .25s ease;
}

.product-panel .products-container .product .customer-groups .entry .img:hover .overlay {
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 1;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .entry
  .group
  .label {
  color: #1a76ca;
  margin-bottom: 3px;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .entry
  .group
  .btn {
    width: fit-content;
  padding: 2.5px 30px;
  background-color: #1a76ca;
  text-align: center;
  border-radius: 15px;
  font-size: 12px;
  margin-top: 5px;
  cursor: pointer;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .entry
  .group.action {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  font-size: 14px;
  background-color: #1a76ca;
  cursor: pointer;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .entry
  .group
  .act-btn {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  font-size: 12px;
  border: 0;
  border-bottom: 1px solid #131c25;
  color: white;
  padding: 10px 15px;
  background-color: transparent;
  cursor: pointer;
  transition: all .25s ease;
}

.product-panel
  .products-container
  .product
  .customer-groups
  .entry
  .group
  .act-btn:hover {
    background-color: #1a76ca;
  }

  .product-panel
  .products-container
  .product
  .customer-groups
  .entry
  .group
  .act-btn.delete {
    background-color: #7a1c02;
  }

  .product-panel
  .products-container
  .product
  .customer-groups
  .entry
  .group
  .act-btn.delete:hover {
    background-color: #ff3600;
  }

  .copy-btn {
    position: relative;
  }

  .copy-btn .value {
    width: fit-content;
    border-bottom: 2px dotted rgba(255, 255, 255, 0.5);
    transition: all .25s ease;
    font-size: 18px;
    font-family: monospace;
    cursor: pointer;
  }

  .copy-btn:hover .value {
    border-bottom: 2px solid #fff;
  }

.copy-btn .open-copy-settings {
  position: absolute;
  top: 50px;
  width: 150px;
  padding: 5px 10px;
  font-size: 12px;
  color: white;
  background-color: #1a76ca;
  border-radius: 5px;
  opacity: 0;
  transition: all 0.25s ease;
  cursor: pointer;
}

.copy-btn.active .open-copy-settings {
  opacity: 1;
}

.copy-btn .open-copy-settings i {
  position: absolute;
  left: 40px;
  top: -12px;
  font-size: 18px;
  color: #1a76ca;
}

.comment-display {
  position: relative;
  height: 0;
  overflow: hidden;
  background-color: #131c25;
}

.comment-display.active {
  height: auto;
  margin-bottom: 15px;
}

.comment-display p.count {
  position: absolute;
  top: 25px;
  right: 20px;
  font-size: 14px;
  color: #1a76ca;
}

.comment-display p.title {
  color: #1a76ca;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 20px 20px 0;
}

.comment-display p.title .save-comment {
  width: fit-content;
  padding: 5px 15px;
  background-color: #1a76ca;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.comment-display p.title i {
  font-size: 16px;
  margin-left: 5px;
  opacity: 0.1;
  cursor: pointer;
  transition: all 0.25s ease;
}

.comment-display p.title i:hover {
  color: #1a76ca;
  opacity: 1;
}

.comment-display p.title i.fas.fa-check:hover {
  color: green;
  opacity: 1;
}

.comment-display p.title i.fas.fa-times:hover {
  color: red;
  opacity: 1;
}

.comment-display p.details {
  font-weight: normal;
  font-size: 12px;
  padding: 0 20px 20px;
}

.comment-display textarea {
  width: calc(100% - 40px);
  margin: 0 20px 20px;
  height: 150px;
  padding: 10px;
  color: white;
  background-color: #131c25;
  border: 1px solid #1a76ca;
}

/* SINGLE PRODUCT */

.single-product-panel {
  position: relative;
}

.single-product-panel .top-btns-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 99%;
  margin: 0 0 20px;
  transition: all 0.5s ease;
}

.single-product-panel .top-btns-nav .double-btn {
  display: flex;
  align-items: center;
}

.single-product-panel .top-btns-nav .double-btn .back-to-prod {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 39px;
  width: 39px;
  font-size: 24px;
  background-color: #1a76ca;
  color: white;
  border-radius: 50px;
  margin-right: 15px;
  cursor: pointer;
}

.single-product-panel .top-btns-nav select {
  height: 35px;
  width: 80px;
  color: white;
  background-color: #131c25;
  border: 1px solid #1a76ca;
  padding-left: 10px;
}

.single-product-panel.settings-open .top-btns-nav {
  width: calc(100% - 390px);
}

.single-product-panel .settings-open-btn {
  width: fit-content;
  padding: 10px 20px;
  background-color: #1a76ca;
  color: white;
  border-radius: 50px;
  cursor: pointer;
}

.single-product-panel .baetes-viewer-holder {
  position: relative;
  height: 100%;
  max-width: 99%;
  height: calc(100vh - 120px);
  transition: all 0.5s ease;
}

.single-product-panel .baetes-viewer-holder .viewer-iframe {
  width: 100%;
  height: 100%;
  background-image: url(../images/checkered.png);
  background-color: #fff;
  background-size: 20%;
  background-position: center;
  transition: all 0.5s ease;
}

.single-product-panel .baetes-viewer-holder.settings-open {
  max-width: calc(100% - 390px);
  height: calc(100vh - 120px);
}

.single-product-panel .settings-panel {
  position: absolute;
  top: -30px;
  right: -410px;
  width: 380px;
  height: 100vh;
  background-color: #1f2f3e;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: all 0.5s ease;
}

.single-product-panel .settings-panel.open {
  right: -30px;
}

.single-product-panel .settings-panel .expander-btn {
  position: fixed;
  top: 0;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 44px;
  color: white;
  background-color: #1a76ca;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.single-product-panel .settings-panel .expander-btn.open {
  right: 380px;
}

.single-product-panel .settings-panel .panel-bottom-btns {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 25px 0;
  background-color: #18242f;
  z-index: 10;
}

.single-product-panel .settings-panel .update-settings {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 40px;
  padding: 10px 25px;
  background-color: #1a76ca;
  color: white;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}

.single-product-panel .settings-panel .advanced-editor {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #1a76ca;
  color: white;
  border-radius: 40px;
  margin-left: 20px;
  cursor: pointer;
}

.single-product-panel .settings-panel .update-settings.saved-successfully {
  width: 260px;
  height: 60px;
  background-color: #0dac50;
}

.single-product-panel .settings-panel .head {
  position: relative;
  width: 100%;
  background-color: #1a76ca;
  color: white;
  padding: 10px;
  font-size: 20px;
  text-align: center;
}

.single-product-panel .settings-panel .head i {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.single-product-panel .settings-panel .menu {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  padding: 20px;
  opacity: 1;
  transition: all 0.5s ease;
}

.single-product-panel .settings-panel .menu.hide {
  left: -100%;
  opacity: 0;
}

.single-product-panel .settings-panel .menu .entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: white;
  margin-bottom: 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.25s ease;
}

.single-product-panel .settings-panel .menu .entry.close-panel-btn {
  display: none;
}

.single-product-panel .settings-panel .menu .entry:hover {
  background-color: #1a76ca;
  color: white;
}

.single-product-panel .settings-panel .active-setting-panel {
  position: absolute;
  top: 50px;
  left: 100%;
  height: calc(100% - 50px);
  width: 100%;
  padding: 20px;
  padding-bottom: 90px;
  opacity: 0;
  overflow: hidden;
  overflow-y: auto;
  transition: all 0.5s ease;
}

.single-product-panel .settings-panel .active-setting-panel.show {
  left: 0;
  opacity: 1;
}

.single-product-panel .settings-panel .active-setting-panel .label {
  color: #fff;
  margin-bottom: 10px;
}

.single-product-panel .settings-panel .active-setting-panel.lights-menu .label {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin-bottom: 17px;
  cursor: pointer;
}

.single-product-panel
  .settings-panel
  .active-setting-panel.lights-menu
  .label
  i {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 0;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  transform: translateY(-50%);
  background-color: #ff3600;
  cursor: pointer;
  transition: all 0.25s ease;
}

.single-product-panel
  .settings-panel
  .active-setting-panel.lights-menu
  .label
  i:hover {
  transform: translateY(-50%) scale(1.2);
}

.setting-entry.add-light .label {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin-bottom: 17px;
}

.setting-entry.add-light .label i {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 0;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  transform: translateY(-50%);
  background-color: #1a76ca !important;
  cursor: pointer;
  transition: all 0.25s ease;
}

.single-product-panel .settings-panel .active-setting-panel input {
  height: 30px;
  width: 100%;
  border: 0;
  padding-left: 10px;
  margin-bottom: 30px;
}

.single-product-panel .settings-panel .active-setting-panel .chrome-picker {
  width: 100% !important;
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif !important;
}

.single-product-panel
  .settings-panel
  .active-setting-panel
  .chrome-picker
  input {
  margin: 0;
  padding: 0;
  height: 35px !important;
  font-size: 14px !important;
}

.setting-entry {
  margin-bottom: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 30px;
}

.setting-entry:first-of-type {
  border-top: 0;
  padding-top: 0px;
}

.setting-entry .xyz-inputs {
  margin-top: 20px;
}

.setting-entry .xyz-inputs .group {
  display: flex;
  align-items: flex-start;
}

.setting-entry .xyz-inputs .group input {
  width: 70px;
  margin: 0;
  border-radius: 5px;
}

.setting-entry .xyz-inputs .group p {
  color: white;
  margin-right: 10px;
}

.setting-entry .get-current-btn {
  width: 100%;
  background-color: #1a76ca;
  color: white;
  padding: 10px;
  text-align: center;
  margin-top: 15px;
  border-radius: 5px;
  cursor: pointer;
}

.lights-menu .setting-entry {
  height: 50px;
  padding: 15px;
  margin-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: 0;
  overflow: hidden;
  transition: all 0.25s ease;
  cursor: pointer;
}

.lights-menu .setting-entry:last-of-type {
  height: auto;
  background-color: transparent;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0;
  cursor: auto;
}

.lights-menu .setting-entry:hover {
  background-color: #1a76ca;
}

.lights-menu .setting-entry:last-of-type:hover {
  height: auto;
  background-color: transparent;
}

.lights-menu .setting-entry.active {
  height: 690px;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: auto;
}

.lights-menu .setting-entry.ambient.active {
  height: 450px;
}

.active-setting-panel .single-selectors {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.active-setting-panel .single-selectors .btn {
  color: white;
  background-color: #000;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.25s ease;
}

.active-setting-panel .single-selectors .btn:hover,
.active-setting-panel .single-selectors .btn.active {
  background-color: #1a76ca;
}

.on-off-list .entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.on-off-list .entry .label {
  margin-bottom: 0 !important;
  font-size: 14px;
}

.on-off-list .entry select {
  height: 30px;
  border-radius: 5px;
  border: 0;
  padding: 0 10px;
}

.toggle-switch input[type="checkbox"] {
  display: none;
  height: 0;
  width: 0;
  visibility: hidden;
}

.toggle-switch label {
  cursor: pointer;
  text-indent: -9999px;
  width: 50px;
  height: 25px;
  background: #131c25;
  display: block;
  border-radius: 25px;
  position: relative;
}

.toggle-switch label:after {
  content: "";
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 20px;
  transition: 0.3s;
}

.toggle-switch input:checked + label {
  background: #1a76ca;
}

.toggle-switch input:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.toggle-switch label:active:after {
  width: 40px;
}

.advanced-settings-mod {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.advanced-settings-mod .mod {
  background-color: #131c25;
  width: 500px;
  height: 350px;
  border-radius: 10px;
  overflow: hidden;
}

.advanced-settings-mod .mod .head {
  text-align: left;
  background-color: #000;
  font-size: 16px;
}

.advanced-settings-mod .mod .content {
  width: 100%;
  padding: 15px;
}

.advanced-settings-mod .mod .content textarea {
  width: 100%;
  height: 230px;
  background-color: #000;
  color: #fff;
  border: 1px solid #1a76ca;
  border-radius: 5px;
  padding: 10px;
}

.advanced-settings-mod .mod .btns {
  display: flex;
  justify-content: flex-end;
  padding: 0px 15px 5px;
}

.advanced-settings-mod .mod .btns .btn {
  margin-left: 15px;
  color: white;
  padding: 8px 20px;
  border-radius: 50px;
  background-color: #0dac50;
  font-size: 14px;
  cursor: pointer;
}

.advanced-settings-mod .mod .btns .btn:last-of-type {
  background-color: #ff3600;
}

/* PROFILE PAGE */

.profile-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.profile-panel .change-pass-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.profile-panel .change-pass-modal .modal {
  background-color: #18242f;
  border-radius: 10px;
  width: 400px;
}

.profile-panel .change-pass-modal .modal .head {
  padding: 10px 15px;
  color: white;
  background-color: #131c25;
}

.profile-panel .change-pass-modal .modal .error {
  background-color: #ff3600;
  padding: 10px;
  text-align: center;
  color: white;
  font-size: 12px;
}

.profile-panel .change-pass-modal .modal .body {
  padding: 15px 0px;
}

.profile-panel .change-pass-modal .modal .action-btns {
  margin-top: 10px;
}

.profile-panel .user-profile {
  width: 90%;
  max-width: 700px;
  background-color: #1f2f3e;
  border-radius: 10px;
  margin-top: 20px;
}

.profile-panel h2 {
  width: 100%;
  background-color: #18242f;
  color: #fff;
  text-align: center;
  padding: 10px 20px;
  margin: 0 0 20px;
}

.profile-panel .inputs {
  display: flex;
}

.profile-panel .inputs .left,
.profile-panel .inputs .right {
  width: 50%;
}

.profile-panel .input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 0 20px;
}

.profile-panel .input-group .label {
  color: #1a76ca;
  margin-bottom: 8px;
}

.profile-panel .input-group .value {
  color: white;
}

.profile-panel .input-group input {
  height: 35px;
  border: 0;
  background-color: #131c25;
  border: 1px solid #1a76ca;
  color: white;
  padding-left: 10px;
}

.profile-panel .input-group input:disabled {
  opacity: 0.8;
  color: grey;
  cursor: not-allowed;
}

.profile-panel .action-btns {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0 20px;
  margin-top: 50px;
  margin-bottom: 20px;
}

.profile-panel .action-btns .btn {
  padding: 10px 30px;
  background-color: #1a76ca;
  margin-left: 15px;
  color: white;
  cursor: pointer;
}

.profile-panel .action-btns .btn.cancel {
  background-color: #ff3600;
}

/* COMPANIES PAGE */

.companies-panel {
  position: relative;
}

.companies-panel .add-company-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.companies-panel .add-company-modal .modal {
  width: 90%;
  max-width: 400px;
  background-color: #18242f;
  border-radius: 10px;
  overflow: hidden;
}

.companies-panel .add-company-modal .modal .head {
  padding: 10px 30px;
  background-color: #10416e;
  color: white;
}

.companies-panel .add-company-modal .modal .body {
  display: flex;
  flex-direction: column;
  padding: 30px 30px 12px;
}

.companies-panel .add-company-modal .modal .body .label {
  margin: 0;
  color: white;
  font-size: 14px;
}

.companies-panel .add-company-modal .modal .body input {
  margin: 8px 0 20px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #1a76ca;
  padding-left: 10px;
  color: white;
  background-color: #131c25;
}

.companies-panel .add-company-modal .modal .bottom {
  display: flex;
  justify-content: flex-end;
  background-color: #131c25;
  padding: 15px 30px;
}

.companies-panel .add-company-modal .modal .bottom .btn {
  padding: 8px 20px;
  border-radius: 5px;
  color: white;
  background-color: #1f2f3e;
  margin-left: 15px;
  cursor: pointer;
}

.companies-panel .add-company-modal .modal .bottom .btn:first-of-type {
  background-color: #ff3600;
}

.companies-panel .add-company-modal .modal .bottom .btn:last-of-type {
  background-color: #0dac50;
}

.companies-panel .companies-list {
  display: flex;
  flex-wrap: wrap;
  max-height: calc(100vh - 110px);
  padding: 10px 15px;
  overflow: auto;
  overflow-x: hidden;
}

.companies-panel .companies-list .company-entry {
  display: flex;
  position: relative;
  width: 24%;
  margin: 10px 0.5% 10px;
  background-color: #1f2f3e;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid transparent;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: all 0.25s ease;
}

.companies-panel .companies-list .company-entry:hover {
  border: 1px solid #1a76ca;
  transform: scale(1.05);
}

.companies-panel .companies-list .company-entry .delete-btn {
  position: absolute;
  top: -8px;
  right: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: #ff3600;
  color: white;
  opacity: 0;
  transition: all 0.25s ease;
}

.companies-panel .companies-list .company-entry:hover .delete-btn {
  opacity: 1;
}

.companies-panel .companies-list .company-entry .logo-holder-div {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 128px;
  height: 128px;
  margin-right: 10px;
  overflow: hidden;
}

.companies-panel .companies-list .company-entry .logo-area {
  display: block;
  width: 128px;
  height: 128px;
  object-fit: contain;
  background-color: rgba(0,0,0,0.2);
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  margin: auto 0;
  overflow: hidden;
}

.companies-panel .companies-list .company-entry .logo-area img {
  width: 100%;
  margin: 0 auto;
}

.companies-panel .companies-list .company-entry .details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 5px 10px;
}

.companies-panel .companies-list .company-entry .details p {
  width: 35%;
  margin: 0;
  color: white;
  font-size: 14px;
}

.companies-panel .companies-list .company-entry .details .name-holder,
.companies-panel .companies-list .company-entry .details .link-holder {
  display: flex;
  align-items: center;
  width: 100%;
}

.companies-panel .companies-list .company-entry .details .name-holder i.fa-pen,
.companies-panel .companies-list .company-entry .details .link-holder i.fa-pen {
  margin-left: 5px;
  font-size: 14px;
  color: #1a76ca;
  opacity: 0;
  transition: all 0.25s ease;
}

.companies-panel
  .companies-list
  .company-entry
  .details
  .name-holder:hover
  i.fa-pen,
.companies-panel
  .companies-list
  .company-entry
  .details
  .link-holder:hover
  i.fa-pen {
  opacity: 1;
}

.companies-panel .companies-list .company-entry .details .editor input {
  height: 25px;
  background-color: #131c25;
  border: 1px solid #1a76ca;
  border-radius: 5px;
  padding-left: 5px;
  color: white;
}

.companies-panel .companies-list .company-entry .details .editor i {
  margin-left: 8px;
  color: #0dac50;
}

.companies-panel .companies-list .company-entry .details .editor i.fa-times {
  color: #ff3600;
}

.companies-panel .companies-list .company-entry .details p.name {
  width: fit-content;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.companies-panel .companies-list .company-entry .details .link-holder {
  margin-bottom: 10px;
  margin-top: 5px;
}

.companies-panel .companies-list .company-entry .details p.link {
  width: fit-content;
  color: #1a76ca;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.companies-panel .companies-list .company-entry .details p b {
  color: #1a76ca;
}

.companies-panel .companies-list .company-entry .details a p b:hover {
  text-decoration: underline;
}

.users-panel {
  width: 100%;
}

.users-panel .add-user-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.users-panel .add-user-modal .modal {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 90%;
  height: 95%;
  max-width: 400px;
  max-height: 743px;
  background-color: #18242f;
  border-radius: 10px;
  overflow: auto;
}

.users-panel .add-user-modal .modal .create-err {
  text-align: center;
  color: #fff;
  background-color: #ff3600;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
}

.users-panel .add-user-modal .modal .head {
  padding: 10px 30px;
  background-color: #10416e;
  color: white;
}

.users-panel .add-user-modal .modal .body {
  flex: 1;
  padding: 30px 30px 12px;
  overflow: auto;
}

.users-panel .add-user-modal .modal .body .company-holder {
  position: relative;
  width: 100%;
}

.users-panel .add-user-modal .modal .body .company-holder .auto-complete {
  position: absolute;
  top: 60px;
  width: 100%;
  max-height: 120px;
  background-color: #1f2f3e;
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow: auto;
  z-index: 10;
}

.users-panel .add-user-modal .modal .body .company-holder .auto-complete li {
  padding: 10px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: all 0.25s ease;
}

.users-panel
  .add-user-modal
  .modal
  .body
  .company-holder
  .auto-complete
  li:hover {
  background-color: #1a76ca;
}

.invisible-inp {
  display: none;
}

.users-panel .add-user-modal .modal .body .label {
  margin: 0;
  color: white;
  font-size: 14px;
}

.users-panel .add-user-modal .modal .body input {
  width: 100%;
  margin: 8px 0 20px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #1a76ca;
  padding-left: 10px;
  color: white;
  background-color: #131c25;
}

.users-panel .add-user-modal .modal .body select {
  width: 100%;
  margin: 8px 0 20px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #1a76ca;
  padding-left: 10px;
  color: white;
  background-color: #131c25;
}

.users-panel .add-user-modal .modal .body select.disabled {
  opacity: 0.7;
  border: 1px solid #1f2f3e;
  pointer-events: none;
}

.users-panel .add-user-modal .modal .bottom {
  display: flex;
  justify-content: flex-end;
  background-color: #131c25;
  padding: 15px 30px;
}

.users-panel .add-user-modal .modal .bottom .btn {
  padding: 8px 20px;
  border-radius: 5px;
  color: white;
  background-color: #1f2f3e;
  margin-left: 15px;
  cursor: pointer;
}

.users-panel .add-user-modal .modal .bottom .btn:first-of-type {
  background-color: #ff3600;
}

.users-panel .add-user-modal .modal .bottom .btn:last-of-type {
  background-color: #0dac50;
}

.users-panel .users-list {
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: white;
  overflow: auto;
}

.users-panel .users-list .head {
  display: flex;
  align-items: center;
  background-color: #18242f;
  min-width: 1350px;
}

.users-panel .users-list .head p {
  width: calc((100% - 100px) / 7);
  padding: 15px;
}

.users-panel .users-list .head p:last-of-type {
  width: 100px;
}

.users-panel .users-list .entry {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 1350px;
  border-bottom: 1px solid #18242f;
}

.users-panel .users-list .entry:last-of-type {
  border-bottom: 1px solid transparent;
}

.users-panel .users-list .entry .editor {
  display: flex;
  align-items: center;
  width: calc(100% / 7);
}

.users-panel .users-list .entry .editor input {
  width: 60%;
  height: 25px;
  background-color: #131c25;
  border: 1px solid #1a76ca;
  border-radius: 3px;
  padding-left: 5px;
  margin-left: 15px;
  color: white;
}

.users-panel .users-list .entry .editor i {
  margin-left: 7px;
  color: #0dac50;
  cursor: pointer;
}

.users-panel .users-list .entry .editor i.fa-times {
  color: #ff3600;
}

.users-panel .users-list .entry p {
  display: flex;
  width: calc((100% - 100px) / 7);
  padding: 15px;
  font-size: 15px;
}

.users-panel .users-list .entry p i {
  color: #1a76ca;
  margin-left: 8px;
  opacity: 0;
  cursor: pointer;
  transition: all 0.25s ease;
}

.users-panel .users-list .entry p:hover i {
  opacity: 1;
}

.users-panel .users-list .entry .remove-btn {
  padding: 10px 15px;
  color: #fff;
  border-radius: 5px;
  margin: 10px 0;
  font-size: 12px;
  border: 1px solid #ff3600;
  cursor: pointer;
  transition: all 0.25s ease;
}

.users-panel .users-list .entry .remove-btn:hover {
  background-color: #ff3600;
}

.loading-text {
  text-align: center;
  color: white;
}

.no-data {
  text-align: center;
  font-size: 18px;
  margin: 30px 0;
  color: white;
  opacity: 0.7;
}

/* HOTSPOTS CONFIG */

.hotspots-config-panel {
  display: flex;
  position: relative;
  width: 100%;
}

.hotspots-config-panel .viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 400px);
  height: calc(100vh - 60px);
}

.hotspots-config-panel .viewer .url-config-nav {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 20px;
  background-color: #1f2f3e;
  border-radius: 10px;
  margin-bottom: 20px;
  color: white;
}

.hotspots-config-panel .viewer .url-config-nav .btn {
  padding: 8px 25px;
  background-color: #1a76ca;
  border-radius: 50px;
  font-size: 12px;
  margin-right: 15px;
  cursor: pointer;
}

.hotspots-config-panel .viewer .url-config-nav i {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  min-width: 30px;
  border-radius: 30px;
  margin-left: 15px;
  background-color: #1a76ca;
  cursor: pointer;
  transition: all 0.25s ease;
}

.hotspots-config-panel .viewer .url-config-nav input {
  width: 58%;
  height: 30px;
  border: 1px solid #1a76ca;
  background-color: #131c25;
  border-radius: 5px;
  padding-left: 10px;
  color: white;
}

.hotspots-config-panel .viewer iframe {
  height: calc(100% - 80px);
  width: 100%;
  background-image: url("../images/checkered.png");
  background-size: 20%;
  transition: all 0.25s ease;
}

.hotspots-config-panel .viewer iframe.mobile {
  width: 350px;
}

.hotspots-config-panel .side-panel {
  position: absolute;
  top: -30px;
  right: -30px;
  width: 380px;
  height: 100vh;
  background-color: #1f2f3e;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.hotspots-config-panel .side-panel .head {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  font-size: 20px;
  background-color: #1a76ca;
  color: white;
}

.hotspots-config-panel .side-panel .menu {
  height: calc(100% - 45px);
  padding: 20px;
  overflow: auto;
}

.hotspots-config-panel .side-panel .menu .hotspot {
  height: 50px;
  padding: 0 15px 15px;
  margin-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: 0;
  color: white;
  overflow: hidden;
  transition: all 0.25s ease;
}

.hotspots-config-panel .side-panel .menu .hotspot.active {
  height: 640px;
}

.hotspots-config-panel .side-panel .menu .hotspot:hover {
  background-color: #1a76ca;
}

.hotspots-config-panel .side-panel .menu .hotspot.active:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.hotspots-config-panel .side-panel .menu .hotspot .label {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding: 15px 0;
}

.hotspots-config-panel .side-panel .menu .hotspot .label .left-side {
  display: flex;
  align-items: center;
  position: relative;
}

.hotspots-config-panel
  .side-panel
  .menu
  .hotspot
  .label
  .left-side
  .dropdown-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -15px;
  left: -15px;
  width: 20px;
  height: 50px;
  background-color: #131c25;
  color: white;
  cursor: pointer;
  transition: all 0.25s ease;
}

.hotspots-config-panel
  .side-panel
  .menu
  .hotspot
  .label
  .left-side
  .dropdown-icon:hover {
  color: #1a76ca;
}

.hotspots-config-panel
  .side-panel
  .menu
  .hotspot.active
  .label
  .left-side
  .dropdown-icon {
  background-color: transparent;
  color: #1a76ca;
}

.hotspots-config-panel
  .side-panel
  .menu
  .hotspot
  .label
  .left-side
  .dropdown-icon
  i {
  transition: all 0.25s ease;
}

.hotspots-config-panel
  .side-panel
  .menu
  .hotspot.active
  .label
  .left-side
  .dropdown-icon
  i {
  transform: rotate(90deg);
}

.hotspots-config-panel .side-panel .menu .hotspot .label .name-holder {
  margin-left: 15px;
}

.hotspots-config-panel .side-panel .menu .hotspot .label .name {
  display: flex;
}

.hotspots-config-panel .side-panel .menu .hotspot .label .name i {
  color: #10416e;
  margin-left: 7px;
  cursor: pointer;
  transition: all 0.25s ease;
}

.hotspots-config-panel .side-panel .menu .hotspot .label .name i:hover {
  color: #1a76ca;
}

.hotspots-config-panel .side-panel .menu .hotspot .label .name-editor {
  display: flex;
  align-items: center;
}

.hotspots-config-panel .side-panel .menu .hotspot .label .name-editor input {
  padding-left: 10px;
  height: 25px;
  border: 1px solid #1a76ca;
  background-color: #18242f;
  color: white;
}

.hotspots-config-panel .side-panel .menu .hotspot .label .name-editor i {
  color: #0dac50;
  margin-left: 7px;
  font-size: 18px;
  cursor: pointer;
}

.hotspots-config-panel
  .side-panel
  .menu
  .hotspot
  .label
  .name-editor
  i.fa-times {
  color: #ff3600;
}

.hotspots-config-panel .side-panel .menu .hotspot .label .delete-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 0;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  transform: translateY(-50%);
  background-color: #ff3600;
  cursor: pointer;
  transition: all 0.25s ease;
}

.hotspots-config-panel .side-panel .menu .hotspot .label .fa-plus {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 0;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  transform: translateY(-50%);
  background-color: #1a76ca;
  cursor: pointer;
  transition: all 0.25s ease;
}

.hotspots-config-panel .side-panel .menu .hotspot .group {
  position: relative;
  margin-bottom: 30px;
}

.group .suggestion-dropdown {
  position: absolute;
  width: 100%;
  max-height: 150px;
  background-color: #1f2f3e;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: auto;
  z-index: 2;
}

.group .suggestion-dropdown li {
  padding: 5px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  color: white;
  cursor: pointer;
  transition: all 0.25s ease;
}

.group .suggestion-dropdown li:hover {
  background-color: #1a76ca;
}

.group .label {
  color: white;
  margin-bottom: 5px;
}

.hotspots-config-panel .side-panel .menu .hotspot .group:first-of-type {
  margin-top: 10px;
}

.group input {
  width: 100%;
  height: 30px;
  border: 1px solid #1a76ca;
  background-color: #131c25;
  border-radius: 5px;
  padding-left: 10px;
  color: white;
}

.group input.font-inp {
  height: 40px;
}

.hotspots-config-panel .side-panel .menu .hotspot .group select {
  width: 100%;
  height: 30px;
  border: 1px solid #1a76ca;
  background-color: #131c25;
  border-radius: 5px;
  padding-left: 10px;
  color: white;
}

.hotspots-config-panel .side-panel .menu .hotspot .group textarea {
  width: 100%;
  height: 100px;
  border: 1px solid #1a76ca;
  background-color: #131c25;
  border-radius: 5px;
  padding: 10px;
  color: white;
  font-family: sans-serif;
}

.hotspots-config-panel .side-panel .menu .hotspot .position-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hotspots-config-panel
  .side-panel
  .menu
  .hotspot
  .position-input
  .input-holder {
  display: flex;
  align-items: center;
}

.hotspots-config-panel
  .side-panel
  .menu
  .hotspot
  .position-input
  .input-holder
  input {
  width: 75px;
  margin-left: 10px;
}

.hotspots-config-panel .side-panel .menu .hotspot .group .current-cord-btn {
  width: 100%;
  background-color: #1a76ca;
  color: white;
  padding: 10px;
  text-align: center;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
}

.no-viewer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 80px;
  width: calc(100% - 400px);
  height: calc(100% - 80px);
  background-color: #1f2f3e;
  color: white;
  font-size: 22px;
  text-align: center;
  line-height: 38px;
  letter-spacing: 3px;
}

/* TOOLS PAGE */

.tools-panel {
  width: 100%;
}

.tools-panel .cards {
  display: flex;
  width: 100%;
}

.tools-panel .cards .card {
  width: 250px;
  margin: 10px 20px;
  background-color: #18242f;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.25s ease;
}

.tools-panel .cards .card:hover {
  transform: scale(1.1);
}

.tools-panel .cards .card .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  font-size: 70px;
  color: #1a76ca;
}

.tools-panel .cards .card .label {
  text-align: center;
  background-color: #1a76ca;
  color: white;
  padding: 10px;
}

.docs-panel {
  width: 100%;
  height: 100%;
}

.docs-panel iframe {
  width: 100%;
  height: 100%;
}

/* PRODUCT WIZARD */

.product-wizard {
  display: flex;
  justify-content: center;
}

.product-wizard .components-holder {
  width: 90%;
  max-width: 1000px;
}

.product-wizard .components-holder .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.product-wizard .components-holder .head .upload-btn {
  padding: 8px 25px;
  background-color: #1a76ca;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.product-wizard .components-holder .head .title {
  color: white;
  font-size: 20px;
}

.product-wizard .components-holder .head .title i.fas.fa-pen {
  font-size: 14px;
  color: #1a76ca;
  margin-left: 5px;
  opacity: 1;
  cursor: pointer;
  transition: all 0.25s ease;
}

.product-wizard .components-holder .head .title:hover i.fas.fa-pen {
  opacity: 1;
}

.product-wizard .components-holder .head .title .editor {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;
}

.product-wizard .components-holder .head .title .editor .prods-suggestion {
  position: absolute;
  top: 32px;
  left: 0;
  width: 100%;
  max-height: 170px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow: auto;
}

.product-wizard
  .components-holder
  .head
  .title
  .editor
  .prods-suggestion
  .head {
  background-color: #10416e;
  color: white;
  padding: 5px;
  font-size: 12px;
  margin-bottom: 0;
}

.product-wizard .components-holder .head .title .editor .prods-suggestion li {
  background-color: #18242f;
  padding: 10px;
  font-size: 12px;
  border-bottom: 1px solid #131c25;
  cursor: pointer;
  transition: all 0.25s ease;
}

.product-wizard
  .components-holder
  .head
  .title
  .editor
  .prods-suggestion
  li:hover {
  background-color: #1a76ca;
}

.product-wizard .components-holder .head .title .editor input {
  height: 30px;
  background-color: #131c25;
  border: 1px solid #1a76ca;
  border-radius: 3px;
  color: white;
  padding-left: 10px;
}

.product-wizard .components-holder .head .title .editor i {
  margin-left: 8px;
  font-size: 18px;
  color: #0dac50;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.25s ease;
}

.product-wizard .components-holder .head .title .editor i.fa-times {
  color: #ff3600;
}

.product-wizard .components-holder .head .title .editor i:hover {
  opacity: 1;
}

.product-wizard .components-holder .head .window-actions {
  display: flex;
}

.product-wizard .components-holder .head i.fas.fa-minus,
.product-wizard .components-holder .head i.fas.fa-plus {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  background-color: #1a76ca;
  margin-left: 10px;
  color: white;
  cursor: pointer;
}

.product-wizard .components-holder .head i.fa-times.delete-entry {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  background-color: #ff3600;
  margin-left: 10px;
  color: white;
  cursor: pointer;
}

.product-wizard .components-holder .add-btn {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 10px 15px;
  background-color: #1a76ca;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.product-wizard .components-holder .add-btn i {
  margin-right: 5px;
}

.product-wizard .components-holder .part {
  padding: 25px 40px;
  margin-bottom: 20px;
  background-color: #1f2f3e;
  border-radius: 10px;
  overflow: hidden !important;
}

.product-wizard .components-holder .mesh {
  padding: 25px 40px;
  background-color: #18242f;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden !important;
}

.product-wizard .components-holder .material {
  padding: 25px 40px;
  background-color: #131c25;
  margin-bottom: 20px;
  border-radius: 10px;
}

.product-wizard .components-holder .material .available-texture-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
}

.product-wizard .components-holder .material .available-texture-list .texture {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  font-size: 12px;
  border-radius: 50px;
  background-color: #1a76ca;
  margin: 0 10px 15px 0;
  color: white;
  cursor: pointer;
}

.product-wizard
  .components-holder
  .material
  .available-texture-list
  .texture
  i {
  margin-right: 5px;
}

.product-wizard .components-holder .material .picked-textures {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.product-wizard .components-holder .material .picked-textures .card {
  position: relative;
  height: 215px;
  width: 170px;
  background-color: #1f2f3e;
  margin: 0 10px 20px;
  padding: 10px;
}

.product-wizard .components-holder .material .picked-textures .card .filename {
  font-size: 12px;
  font-style: italic;
  text-align: center;
  color: #fff;
  opacity: 0.5;
  margin-top: 8px;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.product-wizard
  .components-holder
  .material
  .picked-textures
  .card
  i.delete-card {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  color: white;
  background-color: #ff3600;
  cursor: pointer;
  z-index: 5;
}

.product-wizard
  .components-holder
  .material
  .picked-textures
  .card .albedo-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: calc(100% - 45px);
    background-color: #131c25;
    padding: 10px;
  }

  .product-wizard
  .components-holder
  .material
  .picked-textures
  .card .albedo-selector .select-btn {
    width: 100%;
    padding: 10px;
    background-color: #10416e;
    color: white;
    border-radius: 5px;
    margin: 5px 0;
    text-align: center;
    cursor: pointer;
    transition: all .25s ease;
  }

  .product-wizard
  .components-holder
  .material
  .picked-textures
  .card .albedo-selector .select-btn:hover {
    background-color: #1a76ca;
  }

  .product-wizard
  .components-holder
  .material
  .picked-textures .chrome-picker div + div > div + div div:first-of-type div div span {
    display: none !important;
  }

  .product-wizard
  .components-holder
  .material
  .picked-textures .chrome-picker div + div > div + div > div:not(.flexbox-fix) {
    display: none !important;
  }

.product-wizard
  .components-holder
  .material
  .picked-textures
  .card
  .preview-holder {
  position: relative;
  width: 100%;
  height: calc(100% - 45px);
}

.product-wizard
  .components-holder
  .material
  .picked-textures
  .card
  .preview-holder
  .checker-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/checkered.png");
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.product-wizard .components-holder .material .picked-textures .card .preview {
  position: relative;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  z-index: 2;
}

.product-wizard
  .components-holder
  .material
  .picked-textures
  .card
  .preview
  .label {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 10px;
  margin: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
}

.product-wizard .components-holder .material .picked-textures .card .label {
  width: 100%;
  color: white;
  margin-bottom: 10px;
  font-size: 12px;
}

.product-wizard
  .components-holder
  .material
  .picked-textures
  .card
  .label
  i.fas.fa-pen {
  font-size: 14px;
  color: #1a76ca;
  margin-left: 5px;
  opacity: 1;
  cursor: pointer;
  transition: all 0.25s ease;
}

.product-wizard
  .components-holder
  .material
  .picked-textures
  .card
  .label:hover
  i.fas.fa-pen {
  opacity: 1;
}

.product-wizard
  .components-holder
  .material
  .picked-textures
  .card
  .label
  .editor {
  display: flex;
  align-items: center;
}

.product-wizard
  .components-holder
  .material
  .picked-textures
  .card
  .label
  .editor
  input {
  width: 110px;
  height: 20px;
  background-color: #131c25;
  border: 1px solid #1a76ca;
  border-radius: 3px;
  color: white;
  padding-left: 5px;
  font-size: 12px;
}

.product-wizard
  .components-holder
  .material
  .picked-textures
  .card
  .label
  .editor
  i {
  margin-left: 4px;
  font-size: 16px;
  color: #0dac50;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.25s ease;
}

.product-wizard
  .components-holder
  .material
  .picked-textures
  .card
  .label
  .editor
  i.fa-times {
  color: #ff3600;
}

.product-wizard
  .components-holder
  .material
  .picked-textures
  .card
  .label
  .editor
  i:hover {
  opacity: 1;
}

.product-wizard .components-holder .material .picked-textures .card .drop-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  font-style: italic;
  text-align: center;
  color: #000;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 1;
  font-size: 12px;
  z-index: 3;
}

.product-wizard .drag-drop-zone {
  padding: 15px;
  background-color: #1f2f3e;
  border-radius: 10px;
  margin: 15px 0;
}

.product-wizard .drag-drop-zone .drop-area {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  font-style: italic;
  border: 2px dashed #10416e;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: all 0.5s ease;
}

.product-wizard .drag-drop-zone .drop-area.activated {
  border: 2px solid #10416e;
  background-color: #10416e;
  color: #fff;
  font-size: 20px;
  border-radius: 5px;
}

/* PREVIEW GENERATOR TOOL */

.preview-panel {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.preview-panel .generator {
  width: 400px;
  height: fit-content;
  background-color: #1f2f3e;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
}

.preview-panel .generator .head {
  padding: 10px 20px;
  background-color: #1a76ca;
  color: white;
}

.preview-panel .generator .body {
  padding: 20px;
}

.preview-panel .generator .body .input-group {
  margin-bottom: 20px;
}

.preview-panel .generator .body .input-group p {
  color: white;
  font-size: 14px;
  margin-bottom: 10px;
}

.preview-panel .generator .body .input-group input {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #1a76ca;
  background-color: #131c25;
  color: white;
  padding-left: 10px;
}

.preview-panel .generator .body .label {
  font-size: 14px;
  color: white;
  margin-bottom: 15px;
}

.preview-panel .generator .body .new-resolution {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 10px;
  background-color: #131c25;
  border-radius: 10px;
  margin-bottom: 15px;
}

.preview-panel .generator .body .new-resolution .times {
  color: white;
}

.preview-panel .generator .body .new-resolution .delete-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -10px;
  right: -10px;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  font-size: 14px;
  background-color: #ff3600;
  color: white;
  cursor: pointer;
}

.preview-panel .generator .body .res-input {
  display: flex;
  align-items: center;
}

.preview-panel .generator .body .res-input p {
  color: white;
  margin-right: 10px;
}

.preview-panel .generator .body .res-input input {
  height: 30px;
  width: 100px;
  border-radius: 5px;
  border: 1px solid #1a76ca;
  background-color: #131c25;
  color: white;
  padding-left: 10px;
}

.preview-panel .generator .body .entry-add {
  display: flex;
  align-items: center;
  color: white;
  background-color: #1a76ca;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
}

.preview-panel .generator .body .entry-add i {
  margin-right: 10px;
}

.preview-panel .generator .body .download-btn {
  color: white;
  background-color: #1a76ca;
  text-align: center;
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 1650px) {
  .companies-panel .companies-list .company-entry {
    width: 32%;
  }
}

@media only screen and (max-width: 1280px) {
  .view-holder {
    padding-left: 0;
    padding-top: 50px;
  }

  .view-holder.logged-in {
    padding-left: 0;
  }

  .view-holder.logged-in .topnav {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  .topnav .logo-holder {
    padding: 8px;
  }

  .sidenav {
    display: none;
  }

  .filters-bar {
    flex-direction: column;
  }

  .filters-bar input {
    margin-bottom: 20px;
  }

  .companies-panel .companies-list .company-entry {
    width: 49%;
  }

  .companies-panel .companies-list .company-entry:hover {
    transform: scale(1.02);
  }

  .add-btn-area {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin: 30px 0 0;
  }

  .page-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }

  .topnav .logo-holder img {
    height: 24px;
    margin-top: 6px;
  }

  .topnav .links-holder a {
    width: 40px;
    height: 40px;
  }

  .main-page,
  .single-product-panel .settings-panel {
    height: calc(100vh - 50px);
  }

  .product-panel .products-container,
  .companies-panel .companies-list {
    max-height: calc(100vh - 305px);
  }

  .single-product-panel .baetes-viewer-holder {
    height: calc(100vh - 160px);
  }

  .single-product-panel .baetes-viewer-holder.settings-open {
    height: calc(100vh - 140px);
  }

  .single-product-panel .settings-panel .expander-btn {
    top: 50px;
  }
}

@media only screen and (max-width: 850px) {
  .single-product-panel .baetes-viewer-holder.settings-open {
    max-width: 100%;
    max-height: calc(100vh - 170px);
  }

  .single-product-panel.settings-open .top-btns-nav {
    width: 100%;
  }

  .single-product-panel .settings-panel .menu .entry.close-panel-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1a76ca;
    color: white;
  }

  .companies-panel .companies-list .company-entry {
    width: 100%;
  }

  .App {
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 550px) {
  .companies-panel .companies-list {
    zoom: 0.9;
  }
}

.custom-box {
  overflow: visible;
}

.custom-handle {
  position: absolute;
  color: #1a76ca;
  font-size: 20px;
}

.custom-handle-se {
  bottom: -25px;
  right: 0px;
  cursor: se-resize;
}
